import React, { useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { useSwipeable } from 'react-swipeable';

import { FiArrowLeftCircle } from 'react-icons/fi';
import { FiArrowRightCircle } from 'react-icons/fi';

import './carousel.styles.scss';

const Carousel = ({ itemList }) => {
  const [position, setPosition] = useState(0);

  const goRight = () => {
    if (position < itemList.length - 1) {
      setPosition(position + 1);
    }
  };

  const goLeft = () => {
    if (position > 0) {
      setPosition(position - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: goRight,
    onSwipedRight: goLeft
  });

  let itemLength = itemList.length;
  let enableButtons = true;
  if (itemLength < 0) {
    let enableButtons = true;
  } else {
    let enableButtons = false;
  }

  return (
    <div className="carousel">
      <div {...handlers} className="carousel-row">
        {itemList.map((url, index) => (
          <motion.figure
            className="carousel-container"
            key={index}
            initial={{ scale: 0, rotation: -180 }}
            animate={{
              rotate: 0,
              x: `${(index - position) * 50 + 25.5}vw`,
              scale: index === position ? 1 : 0.8,
              opacity: index === position ? 1 : 0.4
            }}
            transition={{
              type: 'spring',
              stiffness: 250,
              damping: 25
            }}
          >
            <img src={url} alt={'carousel-img'} />
          </motion.figure>
        ))}
      </div>
      {/* <div className="carousel-btn-container">
        <button className="carousel-btn carousel-btn--left" onClick={goLeft}>
          <FiArrowLeftCircle />
        </button>
        <button className="carousel-btn carousel-btn--right" onClick={goRight}>
          <FiArrowRightCircle />
        </button>
      </div> */}
    </div>
  );
};

export default Carousel;
