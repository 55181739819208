import React from 'react';

const Arrow1 = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '150'}
    viewBox="0 0 238.53 57.57"
  >
    <title>Arrow 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M.24,57.57c14.86-.63,30-5.8,44.36-9.64A448.12,448.12,0,0,0,87.72,34.35c8.6-3.27,23.71-7.17,26.53-17.58,2.67-9.88-7.92-14.86-15.85-16C85.68-1.19,70.92.58,59.78,7.35c-8.07,4.9-15.41,13.47-13.55,23.55,2.09,11.37,13.6,17,24,18.57,14.25,2.09,29.36,1,43.63-.4,34.44-3.43,68.13-12.26,100.83-23.3a1.31,1.31,0,0,0-.7-2.53c-24.2,5.62-48.13,12.55-72.55,17.18a310.9,310.9,0,0,1-38.92,5.08c-12,.75-25.59,1.9-37.22-1.47C54.1,40.8,45.63,29.72,53.23,18.76,59.8,9.28,72.45,5.49,83.36,4.46c6.62-.63,33.46-.39,26.24,13-2.46,4.58-8.14,7-12.67,8.91-6.28,2.68-12.67,5.08-19.09,7.41C65.13,38.42,52.19,42.37,39.17,46S12.36,51.76.19,57.4c-.09,0,0,.18,0,.17Z"
          transform="translate(-0.14)"
        />
        <path
          d="M213.2,43.28q12.5-14.12,25-28.24a1.89,1.89,0,0,0-1.33-3.19c-9.74.07-19.78-.51-29.47.38a1.22,1.22,0,0,0,0,2.44c9.68,1.06,19.73.67,29.47.92l-1.32-3.2q-11.67,15-23.26,30c-.5.64.36,1.57.93.92Z"
          transform="translate(-0.14)"
        />
      </g>
    </g>
  </svg>
);

export default Arrow1;
