import React from 'react';

import './heading-secondary.styles.scss';
import colors from '../../../../sass/_variables.module.scss';

function checkOverlappingValuesHighlighted(arr1, arr2) {
  return arr1.filter(function (n) {
    return arr2.indexOf(n) !== -1;
  });
}

function checkOverlappingValuesUnderlined(arr1, arr2) {
  return arr1.filter(function (n) {
    return arr2.indexOf(n) !== -1;
  });
}

const HeadingSecondary = ({
  bold,
  text,
  color,
  highlighted,
  underlined,
  margin,
  transform,
  fontsize,
  className
}) => {
  // split de string
  const textSplit = text.split(' ');

  // create a variable with all words in blue by checking overlapping values van de string met wordsHighlighted
  const wordsHighlighted = checkOverlappingValuesHighlighted(
    textSplit,
    highlighted ?? ''
  );
  const wordsUnderlined = checkOverlappingValuesUnderlined(
    textSplit,
    underlined ?? ''
  );

  return (
    <h2
      style={{
        color: colors[color] ?? colors.colorPrimary,
        marginBottom: margin ?? '2rem',
        transform: transform ?? 'translate(0, 0)',
        fontSize: fontsize ?? '3.3rem'
      }}
      className={`${className} heading-secondary`}
    >
      {textSplit.map((word, index) => {
        if (wordsHighlighted.includes(word)) {
          return (
            <span
              key={index}
              className={`${
                bold ? 'heading-secondary--bold' : 'heading-secondary--thin'
              } heading-secondary--highlighted`}
            >
              {word}
            </span>
          );
        } else if (wordsUnderlined.includes(word)) {
          return (
            <span
              key={index}
              className={`${
                bold ? 'heading-secondary--bold' : 'heading-secondary--thin'
              } heading-secondary--underlined`}
            >
              {word}
            </span>
          );
        } else {
          return (
            <span
              key={index}
              className={
                bold ? 'heading-secondary--bold' : 'heading-secondary--thin'
              }
            >
              {word}
            </span>
          );
        }
      })}
    </h2>
  );
};

export default HeadingSecondary;
