import { configureStore } from '@reduxjs/toolkit';

// the middleware is just for the logging of our redux state. It catches the state, logs it, then moves it aloong the root-reducer
import logger from 'redux-logger';

// import reducers
import darkOverlayReducer from './darkoverlay';
import clientEmailReducer from './clientEmail';

const middleware = [];

const reducer = {
  darkOverlay: darkOverlayReducer,
  clientEmail: clientEmailReducer
};

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export default configureStore({
  reducer,
  middleware: [logger]
});
