import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './footer.styles.scss';
import { FiArrowRight } from 'react-icons/fi';
import { FiMail } from 'react-icons/fi';
import { FiLinkedin } from 'react-icons/fi';

import Paragraph from '../../atoms/paragraph/paragraph.component';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import { useDispatch } from 'react-redux';
import { toggleDarkOverlay } from '../../../redux/darkoverlay';
import { update } from '../../../redux/clientEmail';
import CSTMLogo from '../../../assets/logo';

const Footer = () => {
  const [input, setInput] = useState('');
  const dispatch = useDispatch();

  function handleChange(e) {
    setInput(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    // save email to redux state
    dispatch(update(input));

    // open contact form
    dispatch(toggleDarkOverlay());
  }

  return (
    <div className="footer-container">
      <div className="footer-top">
        <CSTMLogo size={2} color={'#FFF'} />
      </div>
      <div className="footer-content">
        <div className="footer-content-left">
          <HeadingSecondary
            text={'Wil je met ons'}
            color={'colorWhite'}
            bold
            highlighted={['ons']}
            margin={-10}
          />
          <HeadingSecondary text={'samenwerken?'} color={'colorWhite'} bold />
          <Paragraph
            color="colorWhite"
            text="Zoek je naar oplossingen om jouw efficiëntie te vergroten maar heb je daarvoor web/app expertise nodig? Met plezier kijken wij uit naar een kans om samen te werken. Neem gerust contact op!"
            noMargin
            style={{ maxWidth: '60ch' }}
          />
        </div>

        <div className="footer-content-right">
          <HeadingTertiatry
            text={'Neem contact op'}
            className={'footer-heading-tertiary'}
          />
          <form className="form" onSubmit={handleSubmit}>
            <input
              className="form-input"
              type="email"
              placeholder="jouw@email.com"
              value={input}
              onChange={handleChange}
            />
            <FiArrowRight className="form-send" onClick={handleSubmit} />
          </form>

          <div className="socials-visit-container">
            <div className="socials-visit-container-left">
              <HeadingTertiatry
                text={'Socials'}
                className={'footer-heading-tertiary'}
              />
              <a href="mailto:info@cstm-development.nl" target="_blank">
                <FiMail className="socials-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/cstm-development/about/"
                target="_blank"
              >
                <FiLinkedin className="socials-icon" />
              </a>
            </div>
            <div className="socials-visit-container-right">
              <div>
                <HeadingTertiatry
                  text={'Navigatie'}
                  className={'footer-heading-tertiary'}
                />
                <Link className={'link_decoration'} to="/">
                  <Paragraph color="colorWhite" text="Home" />
                </Link>
                <Link className={'link_decoration'} to="/over-ons">
                  <Paragraph color="colorWhite" text="Over ons" />
                </Link>
                <Link className={'link_decoration'} to="/diensten">
                  <Paragraph color="colorWhite" text="Diensten" />
                </Link>
                {/* <Link className={'link_decoration'} to="ons-werk">
                  <Paragraph color="colorWhite" text="Ons Werk" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-left">
          <span className="footer-link">CSTM Development, The Netherlands</span>
        </div>
        <div className="footer-bottom-right">
          <Link to="/credits" className="footer-link">
            Credits
          </Link>
          <Link to="/terms-and-conditions" className="footer-link">
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
