import React from 'react';
import HeadingTertiary from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';

//Icons
import Arrow3 from '../../../assets/icons/arrow_3';
import { CgInfinity } from 'react-icons/cg';

import './what_we_value.styles.scss';

const WhatWeValue = () => {
  return (
    <div id="whatWeValue" className="whatWeValue-biggrid">
      <div className="whatWeValue-container">
        <div className="whatWeValue-values-outer">
          <div className="whatWeValue-values">
            <HeadingTertiary text={'WAARDEN'} />
            <Arrow3 />
          </div>
          <h2 className="whatWeValue-heading">Onze essentie</h2>
        </div>

        <div className="whatWeValue-card card--1">
          <h4>Wij zijn doelgericht</h4>
          <Paragraph
            text="We willen er zeker van zijn dat de doelen van onze klanten worden behaald. We begrijpen dat niet elke klant direct een duidelijk doel voor ogen heeft. Dat is waarom wij jou ondersteunen door goed te luisteren naar jouw behoeften. 
          Met heldere requirements als resultaat!"
          />
        </div>

        <div className="whatWeValue-card card--2">
          <CgInfinity fontSize={80} />
          <h4>Hoge kwaliteit, lage prijs</h4>
          <Paragraph
            text="Onze missie is om softwaregerichte oplossingen te bieden tegen een betaalbare prijs. Dit doen wij door ons team samen te stellen van jonge, gepassioneerde studenten. 
          Zo kunnen wij een out of the box blik bieden op de uitdagingen van onze klanten. Wij houden zo de kwaliteit hoog en jouw kosten betaalbaar."
          />
        </div>

        <div className="whatWeValue-mission-outer">
          <div className="whatWeValue-mission">
            <HeadingTertiary text={'MISSIE'} />
            <Arrow3 />
          </div>
          <h2 className="whatWeValue-heading">One mission</h2>
        </div>
      </div>
    </div>
  );
};

export default WhatWeValue;
