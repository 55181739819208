import React from 'react';

import athaloslogo from '.././../../assets/img/athaloscomingsoon.jpg';
import cstmsite from '.././../../assets/img/cstmsite.jpg';

import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Carousel from '../../molecules/carousel/carousel.component';

import './our-work-section.styles.scss';

const images = [athaloslogo];

const OurWorkSection = () => (
  <div className="ourWorkSection-container">
    <HeadingTertiatry text={'Projecten'} />

    <HeadingSecondary
      text={'Onze nieuwste samenwerking'}
      bold
      underlined={['nieuwste']}
      margin={'7rem'}
    />
    <Carousel itemList={images} />
  </div>
);

export default OurWorkSection;
