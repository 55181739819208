import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';
import Paragraph from '../../atoms/paragraph/paragraph.component';

import './stepper.styles.scss';

const stepperVariant = {
  hidden: {
    y: -10,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      opacity: { duration: 0.2 },
      y: { duration: 0.7, ease: 'ease' },
      staggerChildren: 0.1
    }
  }
};

const itemsVariant = {
  hidden: {
    opacity: 0,
    x: 10
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1
    }
  }
};

const Stepper = ({ item: { id, title, items } }) => {
  return (
    <motion.div variants={stepperVariant} className="stepper">
      <div className="stepper-title">
        <div className="stepper-title-number">
          <Paragraph text={String(id)} bold color={'colorWhite'} noMargin />
        </div>
        <Paragraph
          text={title}
          bold
          color={'colorPrimary'}
          noMargin
          cname={'stepper-title-number-p'}
        />
      </div>

      {items.map((item, i) => (
        <motion.div variants={itemsVariant} className="stepper-item" key={i}>
          <div className="stepper-item-number"></div>
          <Paragraph
            text={String(item)}
            color={'colorPrimary'}
            noMargin
            cname={'stepper-item-number-p'}
          />
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Stepper;