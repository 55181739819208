import React from 'react';

import './we_build.styles.scss';
import WeBuildBlock from '../../molecules/we-build-block/we_build_block.component';

import { BiDesktop, BiMobile, BiCodeAlt, BiCoinStack } from 'react-icons/bi';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';

const WeBuild = () => {
  const webuilddict = [
    {
      heading: 'Websites',
      text: 'Volledig responsive en flexibel aanpasbare websites met CSTM-built code.',
      icontitle: BiCodeAlt
    },
    {
      heading: 'Web apps',
      text: 'Het bouwen van (progressive) web apps, gerealiseerd met een volledige back-end.',
      icontitle: BiDesktop
    },
    {
      heading: 'Full Stack',
      text: 'Wil jij een systeem met alles erop en eraan? Jij vraagt, wij bouwen.',
      icontitle: BiCoinStack
    },
    {
      heading: 'Mobile Apps',
      text: 'Wij bouwen apps voor zowel Apple als Android apparaten.',
      icontitle: BiMobile
    }
  ];
  return (
    <div className="we-build-container">
      <div className="we-build-container-head">
        <HeadingTertiatry text={'Wij bouwen..'} />
      </div>

      <div className="we-build-container-row">
        {webuilddict.map((item, key) => (
          <WeBuildBlock
            key={key}
            heading={item.heading}
            text={item.text}
            icon={<item.icontitle size={55} color="#BE63F9" />}
          />
        ))}
      </div>
    </div>
  );
};

export default WeBuild;
