import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import { FiChevronDown } from 'react-icons/fi';

import './tools-card.styles.scss';

const childVariant = {
  hidden: {
    opacity: 0,
    y: -10
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8
    }
  }
};

const ToolsCard = ({ heading, text, img, iconList }) => {
  const [expand, setExpand] = useState(false);
  const elementRef = useRef(null);

  const toggleExpand = () => setExpand(!expand);

  useEffect(() => {
    let elHeight = elementRef.current.scrollHeight + 'px';
    let el = elementRef.current;

    expand
      ? el.style.setProperty('height', elHeight)
      : el.style.setProperty('height', '0');
  }, [expand]);

  return (
    <motion.div variants={childVariant} className="tools-card-container">
      <figure
        className="tools-card-image"
        style={{ backgroundImage: `url(${img})` }}
      ></figure>
      <div className="tools-card-logo-row">{iconList}</div>
      <Paragraph
        cname={'tools-card-title'}
        text={heading}
        fontSize={'2rem'}
        color={'colorWhite'}
      />
      <div ref={elementRef} className="tools-card-text-container">
        <Paragraph cname={'tools-card-text'} text={text} />
      </div>
      <FiChevronDown
        className={`${expand && 'tools-card-arrow--rotate'} tools-card-arrow`}
        color="white"
        fontSize={'4rem'}
        onClick={toggleExpand}
      />
    </motion.div>
  );
};

export default ToolsCard;
