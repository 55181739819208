import React from 'react'
import { DiReact } from 'react-icons/di';
import { RiUserHeartLine } from 'react-icons/ri';
import { BiAnalyse } from 'react-icons/bi';


import ExpertiseContent from '../../molecules/expertise-content/expertise-content.component';
import './expertise.styles.scss';
import { expertiseObj } from './expertiseTexts';

const Expertise = () => {
  const imgSettings = {
    size: 200,
    color: 'white'
  };

  return (
    <div className="expertise-container">
      <div className="expertise-content">
        <ExpertiseContent expertiseObj={expertiseObj.one} />
        <RiUserHeartLine className={'expertise-right'} {...imgSettings} />
        <DiReact className={'expertise-left'} {...imgSettings} />
        <ExpertiseContent expertiseObj={expertiseObj.two} />
        <ExpertiseContent expertiseObj={expertiseObj.three} />
        <BiAnalyse className={'expertise-right'} {...imgSettings} />
      </div>
    </div>
  );
};

export default Expertise
