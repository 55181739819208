import React, { forwardRef } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import './services-card.styles.scss';

// the forwardref is needed for the individual framer motion animation in this case
const ServicesCard = forwardRef(
  ({ icon, heading, keywordlist, position }, ref) => (
    <div ref={ref} className={'card ' + position}>
      {icon}
      <div className="card-heading">
        <Paragraph text={heading} cname={'card-heading-p'} bold />
      </div>

      <div className="card-keywords">
        <Paragraph text={keywordlist[0]} cname={'card-keywords-p'} />
        <Paragraph text={keywordlist[1]} cname={'card-keywords-p'} />
        <Paragraph text={keywordlist[2]} cname={'card-keywords-p'} lessMargin />
      </div>
    </div>
  )
);

export default ServicesCard;
