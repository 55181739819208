import { createSlice } from '@reduxjs/toolkit';

export const clientEmailSlice = createSlice({
  name: 'clientEmail',
  initialState: {
    email: ''
  },
  reducers: {
    update: (state, action) => {
      state.email = action.payload;
    }
  }
});

export const { update } = clientEmailSlice.actions;

export default clientEmailSlice.reducer;
