import React from 'react';
import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';

import './expertise-content.styles.scss';

const ExpertiseContent = ({ expertiseObj: { title, keywords, text } }) => (
  <div>
    <HeadingSecondary text={title} color={'colorWhite'} bold />
    <Paragraph text={text} color={'colorWhite'} opacity={0.6} />
    <div className="expertise-keywords-grid">
      {keywords.map((keyword, i) => (
        <span key={i}>{keyword}</span>
      ))}
    </div>
  </div>
);

export default ExpertiseContent;
