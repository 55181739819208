import './credits.styles.scss';

const Credits = () => (
  <div className="credits-container">
    <a
      href="https://www.flaticon.com/free-icons/octopus"
      target="_blank"
      rel="noreferrer"
      title="octopus icons"
    >
      Octopus header icon created by Smashicons - Flaticon
    </a>
    <a
      href="https://www.flaticon.com/free-icons/octopus"
      target="_blank"
      rel="noreferrer"
      title="octopus icons"
    >
      Octopus footer icon created by surang - Flaticon
    </a>
    <a href="https://www.flaticon.com/free-icons/person" title="person icons">
      Person icons created by Freepik - Flaticon
    </a>
  </div>
);

export default Credits;
