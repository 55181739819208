import React from 'react';

const Arrow2 = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '100'}
    viewBox="0 0 156.79 130.4"
  >
    <title>Asset 3</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M112.39,31.8c7.06-1.37,13.62-2.42,20.36,1a23.78,23.78,0,0,1,11.49,13,1.79,1.79,0,0,0,3.42,0,159.62,159.62,0,0,0,9.12-44A1.84,1.84,0,0,0,154,.26q-20.22,13.5-40,27.56c-1.16.83-.11,2.73,1.14,1.95Q135.7,16.84,155.88,3.42l-2.75-1.58a167,167,0,0,1-8.89,43h3.42C142.44,31.8,125,21.48,111.92,30.68a.62.62,0,0,0,.47,1.12Z" />
        <path d="M2.74,73a6.45,6.45,0,0,0-.34,10.3C7.83,87.88,15,84,19.84,80.67A186.52,186.52,0,0,1,43.72,66.12c5.36-2.64,13.38-4.83,13.79,3.73.23,4.82-1.4,9.84-2.35,14.52-2.11,10.35-4.77,20.75-6.29,31.21-.84,5.76.55,13.35,7.34,14.66,7.07,1.36,14-5.93,18.37-10.42,6.59-6.79,12-14.64,17.55-22.29,14.18-19.62,28-39.52,40.79-60.07,1.21-1.94-1.7-3.49-3-1.75-10.76,14.47-20.84,29.36-31.11,44.2-9.68,14-18.58,30-31.35,41.44-3.83,3.44-10.23,8.45-14.11,2.41-2.83-4.4-.42-10.76.53-15.49l5.42-26.8c1-5,2.86-11,.82-16.08s-7.06-6-11.77-4.66c-7.49,2.21-14.77,7.5-21.19,11.81-3.82,2.56-7.36,5.61-11.26,8A14.61,14.61,0,0,1,9,83.18c-4.78.27-10.51-5.1-5.55-9.3.48-.41-.16-1.25-.69-.89Z" />
      </g>
    </g>
  </svg>
);

export default Arrow2;
