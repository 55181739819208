import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import './statistics.styles.scss';

import Arrow from '../../../assets/icons/arrow_4';

import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';

import {
  AiOutlineCrown,
  AiOutlineSearch,
  AiOutlineReload,
  AiOutlineExperiment,
  AiOutlineAudit
} from 'react-icons/ai';

import { MdCenterFocusWeak } from 'react-icons/md';

const iconSize = '3.7rem';

const statisticCardContent = [
  {
    heading: 'Minimalistisch ',
    text: 'Houden het bij de essentials',
    icontitle: AiOutlineCrown
  },
  {
    heading: 'Gefocust',
    text: 'Gericht op klantcontact',
    icontitle: MdCenterFocusWeak
  },
  {
    heading: 'Flexibel',
    text: 'Altijd klaar voor verandering',
    icontitle: AiOutlineReload
  },
  {
    heading: 'Innovatief',
    text: 'Out of the box oplossingen',
    icontitle: AiOutlineExperiment
  },
  {
    heading: 'Professioneel',
    text: 'Wij zijn nooit uitgeleerd',
    icontitle: AiOutlineAudit
  },
  {
    heading: 'Geïnformeerd',
    text: 'Op de hoogte van de nieuwste trends',
    icontitle: AiOutlineSearch
  }
];

const childVariants = {
  hidden: {
    opacity: 0,
    y: -10
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.4
    }
  }
};

const StatisticCard = ({ heading, text, icon }) => (
  <motion.div variants={childVariants} className="statisticCard">
    <div className="statisticCard-icon">{icon}</div>
    <div className="statisticCard-heading">
      <Paragraph
        cname={'statisticCard-heading-title'}
        text={heading}
        lessMargin
      />
      <Paragraph cname={'statisticCard-heading-text'} text={text} lessMargin />
    </div>
  </motion.div>
);

const fadeInVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
      delay: 0.3
    }
  }
};

const Statistics = () => (
  <div className={'statistics-container'}>
    <div className={'statistics-container-left'}>
      <div className={'statistics-container-left-heading'}>
        <HeadingTertiatry text={'Waarom CSTM?'} />
        <HeadingSecondary text={'Nou, wij zijn...'} bold />
      </div>
      <Arrow size={120} className={'statistics-container-left-arrow'} />
    </div>

    <div className="statistics-container-right">
      <motion.ul
        variants={fadeInVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-100px' }}
        className="statistics-container-cards"
      >
        {statisticCardContent.map((el, i) => (
          <StatisticCard
            key={i}
            heading={el.heading}
            text={el.text}
            icon={<el.icontitle size={iconSize} color="#171A64" />}
          />
        ))}
      </motion.ul>
    </div>
  </div>
);

export default Statistics;
