import React from 'react';

const CodeIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '121.534'}
    height={size ? size * 0.75 : '91.185'}
    viewBox="0 0 121.534 91.185"
  >
    <path
      id="code"
      d="M38.24,75.085l-3.321,3.321a2.087,2.087,0,0,1-3.054,0L.916,47.458a2.087,2.087,0,0,1,0-3.054L31.865,13.455a2.087,2.087,0,0,1,3.054,0l3.321,3.321a2.087,2.087,0,0,1,0,3.054l-26.1,26.1,26.1,26.1a2.087,2.087,0,0,1,0,3.054ZM77.488,4.225,52.716,89.962a2.165,2.165,0,0,1-1.031,1.294,1.854,1.854,0,0,1-1.558.168l-4.119-1.13a2.165,2.165,0,0,1-1.294-1.031,1.983,1.983,0,0,1-.164-1.626L69.322,1.9A2.165,2.165,0,0,1,70.353.606,1.845,1.845,0,0,1,71.911.441l4.119,1.13A2.165,2.165,0,0,1,77.324,2.6,1.976,1.976,0,0,1,77.488,4.225Zm43.634,43.233L90.174,78.407a2.087,2.087,0,0,1-3.054,0L83.8,75.085a2.087,2.087,0,0,1,0-3.054l26.1-26.1-26.1-26.1a2.087,2.087,0,0,1,0-3.054l3.321-3.321a2.087,2.087,0,0,1,3.054,0L121.122,44.4a2.087,2.087,0,0,1,0,3.054Z"
      transform="translate(-0.252 -0.339)"
      fill={color ?? '#dec0f1'}
    />
  </svg>
);

export default CodeIcon;
