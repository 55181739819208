import React from 'react';

import colors from '../../../../sass/_variables.module.scss';
import './primary-btn.styles.scss';

const PrimaryBtn = ({
  outlined,
  text,
  click,
  light,
  uppercase,
  bold,
  type,
  className,
  icon,
  ...props
}) => {
  return (
    <button
      {...props}
      onClick={click}
      type={type}
      className={`${className} primary-button 
      ${outlined ? 'primary-button--outlined' : 'primary-button--full'} 
      ${uppercase && 'primary-button--uppercase'}
      ${bold ? 'primary-button--bold' : null} primary-button`}
      style={
        light && {
          backgroundColor: outlined ? 'transparent' : colors.colorSecondary,
          color: outlined ? colors.colorSecondary : colors.colorWhite,
          borderColor: outlined ? colors.colorSecondary : 'none'
        }
      }
    >
      {text}
      {icon}
    </button>
  );
};

export default PrimaryBtn;
