import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

const Arrow4 = ({ size, color, className }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '243.882'}
    height={size ? size * 0.544 : '132.788'}
    viewBox="0 0 243.882 132.788"
    className={className}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true, margin: '-100px' }}
  >
    <motion.path
      id="Path_33"
      data-name="Path 33"
      d="M0,0S48,43.606,96.5,43.606,194,0,194,0"
      transform="translate(29.182 17.639) rotate(17)"
      fill="none"
      stroke={color ?? '#dec0f1'}
      strokeLinecap="round"
      strokeWidth="20"
      variants={draw}
    />
    <motion.path
      id="Path_34"
      data-name="Path 34"
      d="M762.665,892.224,823,892l-3.419,61.624"
      transform="translate(-293.599 -1031.491) rotate(17)"
      fill="none"
      stroke={color ?? '#dec0f1'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="18"
      variants={draw}
      custom={-1}
    />
  </motion.svg>
);

export default Arrow4;
