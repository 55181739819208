import React from 'react';

import colors from '../../../../sass/_variables.module.scss';
import './heading-primary.styles.scss';

const HeadingPrimary = ({ textBold, textThin, color, lessMargin }) => (
  <h1 
    style={{
      color: colors[color] ?? colors.colorPrimary,
      marginBottom: lessMargin && 10,
    }} 
    className="heading-primary">
      
    <span className='heading-primary--bold'>{textBold}</span>
    <span className="heading-primary--thin">{textThin}</span>
  </h1>
);

export default HeadingPrimary;
