import React from 'react';

import HomepageLanding from '../../components/organisms/homepage-landing-section/homepage-landing.component';
import OnzeTools from '../../components/organisms/onze-tools-section/onze-tools.component';
import OurWorkSection from '../../components/organisms/our-work-section/our-work-section.component';
import Statistics from '../../components/organisms/statistics-section/statistics.component';
import WatIsCSTM from '../../components/organisms/wat-is-cstm-section/wat-is-cstm.component';
import Footer from '../../components/organisms/footer-section/footer.component';

import './homepage.styles.scss';

const Homepage = () => {
  return (
    <main className="homepage-container">
      <section className="homepage-landing">
        <HomepageLanding />
      </section>
      <section className="what-is-cstm">
        <WatIsCSTM />
      </section>
      <section className="statistics">
        <Statistics />
      </section>
      <section className="onzeTools">
        <OnzeTools />
      </section>
      <section className="our-work">
        <OurWorkSection />
      </section>
      <footer className="homepage-footer">
        <Footer />
      </footer>
    </main>
  );
};

export default Homepage;
