import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import PrimaryBtn from '../../atoms/buttons/primary-btn/primary-btn.component';

import './desktopNav.styles.scss';
import './mobileNav.styles.scss';

// redux actions
import { toggleDarkOverlay } from '../../../redux/darkoverlay';
import HamburgerIcon from '../../atoms/hamburger-icon/hamburger-icon.component';
import CSTMLogo from '../../../assets/logo';

const disabledStyles = {
  color: 'grey',
  textDecoration: 'line-through',
  pointerEvents: 'none'
};

const MobileNav = () => {
  const [menuShown, setMenuShown] = useState(false);
  const [transform, setTransform] = useState('translateY(0)');
  const dispatch = useDispatch();

  const toggleMenu = function () {
    setMenuShown(!menuShown);
  };

  let prevScrollpos = window.scrollY;
  const handleNavbarScroll = function () {
    const currentScrollpos = window.scrollY;

    if (prevScrollpos > currentScrollpos) {
      setTransform('translateY(0)');
    } else {
      setTransform('translateY(-150%)');
    }

    prevScrollpos = currentScrollpos;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarScroll);

    return () => window.removeEventListener('scroll', handleNavbarScroll);
  });

  return (
    <header className="header" style={{ transform: transform }}>
      <div
        className={`${
          menuShown ? 'header-container--round' : 'header-container--semiround'
        } header-container`}
      >
        <Link to="/" className="header-logo-mobile">
          <CSTMLogo size={1} />
        </Link>
        <HamburgerIcon onClick={toggleMenu} open={menuShown} />
      </div>
      <nav className={'mobile-nav'}>
        <ul className={`${menuShown ? 'mobile-menu--active' : ''} mobile-menu`}>
          <NavLink
            className="mobile-nav-item"
            to="/"
            exact
            activeClassName="mobile-nav-item--selected"
            onClick={toggleMenu}
          >
            Home
          </NavLink>
          <NavLink
            className="mobile-nav-item"
            to="/over-ons"
            exact
            activeClassName="mobile-nav-item--selected"
            onClick={toggleMenu}
          >
            Over ons
          </NavLink>
          <NavLink
            className="mobile-nav-item"
            to="/diensten"
            exact
            activeClassName="mobile-nav-item--selected"
            onClick={toggleMenu}
          >
            Diensten
          </NavLink>
          {/* <NavLink
            className="mobile-nav-item"
            to="/ons-werk"
            exact
            activeClassName="mobile-nav-item--selected"
            // onClick={toggleMenu}
            style={disabledStyles}
          >
            Our work
          </NavLink> */}
          <p
            className="mobile-nav-item mobile-nav-item--contact"
            onClick={() => dispatch(toggleDarkOverlay())}
          >
            Contact
          </p>
        </ul>
      </nav>
    </header>
  );
};

const DesktopNav = () => {
  const dispatch = useDispatch();

  return (
    <header className="navigatie-grid-container">
      <div className="navigatie-container">
        <Link className="header-logo" to="/">
          <CSTMLogo size={2} />
        </Link>
        <nav className="navigatie">
          <NavLink
            className="navigatie-item"
            to="/"
            exact
            activeClassName="navigatie-item--selected"
          >
            Home
          </NavLink>
          <NavLink
            className="navigatie-item"
            to="/over-ons"
            exact
            activeClassName="navigatie-item--selected"
          >
            Over ons
          </NavLink>
          <NavLink
            className="navigatie-item"
            to="/diensten"
            exact
            activeClassName="navigatie-item--selected"
          >
            Diensten
          </NavLink>
          {/* <NavLink
            className="navigatie-item navigatie-item--disabled"
            to="/ons-werk"
            exact
            activeClassName="navigatie-item--selected"
            style={disabledStyles}
          >
            Our work
          </NavLink> */}
        </nav>

        <PrimaryBtn
          className="header-btn"
          text="contact"
          click={() => dispatch(toggleDarkOverlay())}
          uppercase
        />
      </div>
    </header>
  );
};

const NavBar = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = function () {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 900;

  return isMobile ? <MobileNav /> : <DesktopNav />;
};

export default NavBar;
