import { motion } from 'framer-motion/dist/framer-motion';

const parentVariant = {
  visible: {
    transition: {
      staggerChildren: 0.15,
      duration: 2
    }
  }
};

const blockVariantOne = {
  hidden: {
    opacity: 0,
    y: -50
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      opacity: { duration: 0.3 },
      y: { duration: 0.6 },
      ease: 'easeOut'
    }
  }
};

const BlocksImage = (props) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={588.628 / 1.5} // this is for making the image smaller, but keeping a bigger viewport so the animation looks better. Otherwise a part of the boxes are cut off during.
    height={628.897 / 1.3}
    viewBox="0 0 588.628 628.897"
    variants={parentVariant}
    initial="hidden"
    animate="visible"
    className={props.className}
    // style={{ border: '1px solid red' }}
  >
    <motion.g variants={blockVariantOne}>
      <path
        id="_20"
        data-name="20"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(147.689 188.069)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_19"
        data-name="19"
        d="M68,272.572l68.73,274.267L283.355,468.78V272.572Z"
        transform="translate(157.584 2.379)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_18"
        data-name="18"
        d="M0,272.572V468.78l146.625,78.058V272.572Z"
        transform="translate(147.689 2.378)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_17"
        data-name="17"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(147.689 -7.536)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
    </motion.g>
    <motion.g variants={blockVariantOne}>
      <path
        id="_16"
        data-name="16"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(294.314 266.917)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_15"
        data-name="15"
        d="M68,272.572l68.73,274.267L283.355,468.78V272.572Z"
        transform="translate(304.209 81.227)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_14"
        data-name="14"
        d="M0,272.572V468.78l146.625,78.058V272.572Z"
        transform="translate(294.314 81.226)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_13"
        data-name="13"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(294.314 71.312)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
    </motion.g>
    <motion.g variants={blockVariantOne}>
      <path
        id="_12"
        data-name="12"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(147.689 -7.652)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_11"
        data-name="11"
        d="M68,272.572l68.73,274.267L283.355,468.78V272.572Z"
        transform="translate(157.584 -193.947)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_10"
        data-name="10"
        d="M0,272.572V468.78l146.625,78.058V272.572Z"
        transform="translate(147.689 -193.948)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_9"
        data-name="9"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(147.689 -203.862)"
        fill="#dec0f1"
        stroke="#707070"
        stroke-width="1"
      />
    </motion.g>
    <motion.g variants={blockVariantOne}>
      <path
        id="_8"
        data-name="8"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(1.064 267.522)"
        fill="#171a64"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_7"
        data-name="7"
        d="M68,272.572l68.73,274.267L283.355,468.78V272.572Z"
        transform="translate(10.959 81.227)"
        fill="#171a64"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_6"
        data-name="6"
        d="M0,272.572V468.78l146.625,78.058V272.572Z"
        transform="translate(1.064 81.226)"
        fill="#171a64"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_5"
        data-name="5"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(1.064 71.312)"
        fill="#171a64"
        stroke="#707070"
        stroke-width="1"
      />
    </motion.g>
    <motion.g variants={blockVariantOne}>
      <path
        id="_4"
        data-name="4"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(294.314 70.918)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_3"
        data-name="3"
        d="M68,272.572l68.73,274.267L283.355,468.78V272.572Z"
        transform="translate(304.209 -115.377)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_2"
        data-name="2"
        d="M0,272.572V468.78l146.625,78.058V272.572Z"
        transform="translate(294.314 -114.774)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
      <path
        id="_1"
        data-name="1"
        d="M146.625,204.428,0,282.487l146.625,78.057L293.25,282.487Z"
        transform="translate(294.314 -125.293)"
        fill="#fce7fd"
        stroke="#707070"
        stroke-width="1"
      />
    </motion.g>
  </motion.svg>
);

export default BlocksImage;
