import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function scrollTo(id) {
  let element = document.getElementById(id);
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}
