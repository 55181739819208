import React from 'react';

import './ons_werk.styles.scss';

//Import components
import Footer from '../../components/organisms/footer-section/footer.component';
import LatestWork from '../../components/organisms/latest-work/latest_work.component';
// import TheWork from '../../components/the-work/the_work.component';
// import JoinedForces from '../../components/organisms/joined-forces/joined_forces.component';

const OnsWerk = () => {
  return (
    <div>
      <main className="onswerk-container">
        <section className="onswerk-latest-work ">
          <LatestWork />
        </section>
        {/* <section className="onswerk-the-work">
          <TheWork />
        </section>
        <section className="onswerk-joined-forces">
          <JoinedForces />
        </section> */}
        <section className="onswerk-footer">
          <Footer />
        </section>
      </main>
    </div>
  );
};

export default OnsWerk;
