import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Homepage from './pages/homepage/homepage.component';
import Diensten from './pages/diensten/diensten.component';
import OverOns from './pages/over_ons/over_ons.component';
import OnsWerk from './pages/ons_werk/ons_werk.component';
import NavBar from './components/molecules/navbar/navbar.component';
import ArrowToTop from './components/atoms/arrow-to-top/arrow-to-top.component';
import PageNotFound from './pages/404/page_not_found.component';

import Credits from './pages/credits/credits.component';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions.component';
import { ScrollToTop } from './helpers/scrollTo';
import ContactCard from './components/organisms/contact-card/contact-card.component';

const App = () => {
  return (
    <>
      <ContactCard />
      <ArrowToTop />
      <Router>
        <ScrollToTop />
        <NavBar />
        <Switch>
          <Route exact path="/over-ons">
            <OverOns />
          </Route>
          <Route exact path="/diensten">
            <Diensten />
          </Route>
          <Route exact path="/ons-werk">
            <OnsWerk />
          </Route>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/credits">
            <Credits />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
