import './page_not_found.styles.scss';
import React from 'react';
import Footer from '../../components/organisms/footer-section/footer.component';
import HeadingPrimary from '../../components/atoms/headings/heading-primary/heading-primary.component';
import CSTMLogo from '../../assets/logo';

function PageNotFound() {
  return (
    <div className="pagenotfound-container">
      <div className="error404-container">
        <CSTMLogo size={3} />
        <HeadingPrimary textThin={'Error 404: Pagina niet gevonden :('} />
      </div>
      <footer className="errorpage-footer">
        <Footer />
      </footer>
    </div>
  );
}

export default PageNotFound;
