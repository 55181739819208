import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import './darkoverlay.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkOverlay } from '../../../redux/darkoverlay';

const Darkoverlay = () => {
  const { hidden } = useSelector((state) => state.darkOverlay);
  const dispatch = useDispatch();

  return hidden ? null : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.15 }}
      className="darkoverlay"
      onClick={() => dispatch(toggleDarkOverlay())}
    ></motion.div>
  );
};
export default Darkoverlay;