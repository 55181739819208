import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Stepper from '../../molecules/stepper/stepper.component';

import './workflow.styles.scss';

const parentVariant = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

const Workflow = () => {
  const workflowItems = [
    {
      id: 1,
      title: 'Voorbereiding',
      items: [
        'Kennismakingsgesprek',
        'Bedrijfsidentiteit',
        'Requirements',
        'Plan van Aanpak'
      ]
    },
    {
      id: 2,
      title: 'Design',
      items: [
        'Wireframes',
        'Mockup design',
        'Feedback',
        'Iteratie',
        'Definitief design'
      ]
    },
    {
      id: 3,
      title: 'Development',
      items: [
        'Definieer bijpassende technieken',
        'Versiebeheer',
        'Omgeving opzetten',
        'Clean coding!',
        'Iteratie'
      ]
    },
    {
      id: 4,
      title: 'Handover',
      items: ['Testen', 'Bug fixing', 'Klant tevreden?', 'Oplevering', 'Nazorg']
    }
  ];

  return (
    <div className="workflow-container">
      <HeadingSecondary text={'Our Workflow'} bold />
      <motion.div
        variants={parentVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-100px' }}
        className="stepper-container"
      >
        {workflowItems.map((workflowItem) => (
          <Stepper key={workflowItem.id} item={workflowItem} />
        ))}
      </motion.div>
    </div>
  );
};

export default Workflow;
