import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import HeadingPrimary from '../../atoms/headings/heading-primary/heading-primary.component';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import ServicesCard from '../../molecules/services-card/services-card.component';

import CodeIcon from '../../../assets/icons/code';
import CrownIcon from '../../../assets/icons/crown-icon';
import PieChartIcon from '../../../assets/icons/piechart-icon';

import './what-we-do.styles.scss';

const MotionCard = motion(ServicesCard);

const cardsVariants = {
  init: {
    opacity: 0,
    y: -15
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

const cardVariant = {
  init: {
    y: '0rem'
  },
  animate: {
    y: '3rem'
  },
  animateLeft: { rotateY: 40, scaleX: 0.9 },
  animateRight: { rotateY: -40, scaleX: 0.9 }
};

const WhatWeDo = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = function () {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isMobile = width <= 600;

  return (
    <div className="whatWeDoSection-container">
      <HeadingTertiatry text={'Services'} />
      <HeadingPrimary textBold="THIS IS WHAT WE DO" lessMargin />
      <Paragraph
        text={`Hier zie je een overzicht van alle diensten die CSTM kan bieden. 
        Onze expertise varieert van business analyse tot design. 
        Ook wanneer je advies nodig hebt bij jouw bestaande producten of diensten, 
        staan wij graag klaar om jou verder te adviseren!`}
        color={'colorPrimary'}
      />

      <motion.div
        variants={cardsVariants}
        initial="init"
        animate="animate"
        className="card-container"
      >
        <MotionCard
          variants={!isMobile && cardVariant}
          animate={['animate', 'animateLeft']}
          transition={{ delay: 0.2, duration: 0.8 }}
          icon={<CrownIcon key={'crown'} size={40} />}
          position={'left'}
          heading={'Design'}
          keywordlist={['Digital Branding', 'UX Design', 'UI Design']}
        />
        <MotionCard
          icon={<CodeIcon key={'code'} size={50} color={'#d580ff'} />}
          heading={'Development'}
          keywordlist={['Production', 'Performance', 'Support']}
        />
        <MotionCard
          variants={!isMobile && cardVariant}
          animate={['animate', 'animateRight']}
          transition={{ delay: 0.2, duration: 0.8 }}
          icon={<PieChartIcon key={'piechart'} size={50} />}
          position={'right'}
          heading={'Analyse'}
          keywordlist={['Strategy ', 'Requirements ', 'Project Management']}
        />
      </motion.div>
    </div>
  );
};

export default WhatWeDo;
