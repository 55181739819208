import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Slide } from 'react-toastify';

import InputField from '../../atoms/input_field/input-field.component';
import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import PrimaryBtn from '../../atoms/buttons/primary-btn/primary-btn.component';

import { FiX } from 'react-icons/fi';

import colors from '../../../sass/_variables.module.scss';
import './contact-card.styles.scss';
import '../../atoms/toast/toast.styles.scss';
import 'react-toastify/dist/ReactToastify.css';

// redux actions
import { toggleDarkOverlay } from '../../../redux/darkoverlay';
import Darkoverlay from '../../atoms/darkoverlay/darkoverlay.component';

const ContactCard = () => {
  const { hidden } = useSelector((state) => state.darkOverlay);
  const { email } = useSelector((state) => state.clientEmail);
  const dispatch = useDispatch();

  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const form = useRef();

  useEffect(() => {
    toast.configure();

    if (successToast === true) {
      toast.success('Email sent succesfully', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Slide
      });

      setSuccessToast(false);
    }

    if (errorToast === true) {
      toast.error('Something went wrong', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Slide
      });

      setErrorToast(false);
    }
  }, [successToast, errorToast]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_0s1y0tz',
        'template_contactform',
        form.current,
        'user_BpSR8BruIkJ7Jt6Xl5UnM'
      )
      .then(
        (result) => {
          setSuccessToast(true);

          console.log(result.text);
          console.log(result);
        },
        (error) => {
          setErrorToast(true);
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <div className="sidebar-container">
      <Darkoverlay />
      <div className={`contact-container ${hidden ? 'hidden' : ''}`}>
        <div className='contact-container-head'>
          <FiX
            color={colors.colorWhite}
            size={30}
            onClick={() => dispatch(toggleDarkOverlay())}
            className="react-icon-regular"
          />
          <HeadingSecondary color={'colorWhite'} text={'Contact | CSTM'} margin={0} bold />
        </div>
        <Paragraph
          text={
            "Ook enthousiast geworden? Stuur ons een bericht om te kijken of een samenwerking mogelijk is. Wij helpen graag!"
          }
          color={'colorWhite'}
        />

        <form className="contact-form" ref={form} onSubmit={sendEmail}>
          <InputField
            type={'text'}
            placeholder={'Naam / Bedrijf'}
            name={'user_name'}
          />
          <InputField
            type={'email'}
            placeholder={'Email*'}
            name={'user_email'}
            defaultValue={email}
            required
          />
          <InputField
            type={'text'}
            placeholder={'Bericht*'}
            name={'message'}
            rows={5}
            lg
            required
          />
          <div className="contact-send-privacy">
            <Paragraph
              text={[
                'Door op ',
                <b>Stuur bericht</b>,
                " te klikken, ga je akkoord met de ",
                <u>Privacy Policy</u>
              ]}
              color={'colorWhite'}
            />

            <PrimaryBtn text={'Stuur bericht'} type={'submit'} light bold />
          </div>
        </form>

        <Paragraph
          text={'of stuur een bericht naar'}
          color={'colorSecondary'}
          lessMargin
          uppercase
        />
        <a
          className="mailus"
          target="_blank"
          href="mailto:info@cstm-development.nl"
        >
          <i>info@cstm-development.nl</i>
        </a>
      </div>
    </div>
  );
};

export default ContactCard;
