import React, { forwardRef } from 'react';

import './paragraph.styles.scss';
import colors from '../../../sass/_variables.module.scss';

const Paragraph = forwardRef(
  (
    {
      text,
      color,
      opacity,
      fontSize,
      bold,
      lessMargin,
      noMargin,
      width,
      uppercase,
      borderleft,
      cname,
      style
    },
    ref
  ) => (
    <p
      ref={ref}
      style={{
        color: colors[color],
        opacity: opacity,
        fontSize: fontSize,
        marginBottom: noMargin ? 0 : lessMargin && 5,
        width: width,
        ...style
      }}
      className={`
      ${bold ? 'paragraph--bold' : null}
      ${uppercase && 'paragraph--uppercase'}
      ${borderleft && 'paragraph--border-left'}
      ${cname}
      paragraph
    `}
    >
      {text}
    </p>
  )
);

export default Paragraph;
