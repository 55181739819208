import React from 'react';

import AddedValue from '../../components/molecules/added-value/added-value.component';
import Expertise from '../../components/organisms/expertise/expertise.component';
import Footer from '../../components/organisms/footer-section/footer.component';
import WhatWeDo from '../../components/organisms/what-we-do/what-we-do.component';
import Workflow from '../../components/organisms/workflow/workflow.component';

import './diensten.styles.scss';

const Diensten = () => {
  return (
    <main className="services-container">
      <section className="what-we-do">
        <WhatWeDo />
      </section>
      <section className="added-value">
        <AddedValue />
      </section>
      <section className="expertise">
        <Expertise />
      </section>
      <section className="workflow">
        <Workflow />
      </section>
      <section className="homepage-footer">
        <Footer />
      </section>
    </main>
  );
};

export default Diensten;
