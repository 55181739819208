import React from 'react';

const CrownIcon = ({ size, colorPrimary, colorSecondary, colorThirtiary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '152.113'}
    height={size ? size * 1.065 : '162'}
    viewBox="0 0 152.113 162"
  >
    <g id="Crown" transform="translate(-1188 -648)">
      <path
        id="Polygon_6"
        data-name="Polygon 6"
        d="M54,0l54,81H0Z"
        transform="translate(1210.056 648)"
        fill={colorPrimary ?? '#f9f'}
      />
      <path
        id="Polygon_7"
        data-name="Polygon 7"
        d="M54,0l54,81H0Z"
        transform="translate(1318.056 810) rotate(180)"
        fill={colorPrimary ?? '#f9f'}
      />
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M47,0,94,22H0Z"
        transform="translate(1318.056 776) rotate(-90)"
        fill={colorSecondary ?? '#d580ff'}
      />
      <path
        id="Polygon_9"
        data-name="Polygon 9"
        d="M47,0,94,22H0Z"
        transform="translate(1210.056 682) rotate(90)"
        fill={colorSecondary ?? '#d580ff'}
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M1485,810"
        transform="translate(-220.944)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M1431,729l-22.056,46.412L1485,810Z"
        transform="translate(-220.944)"
        fill={colorThirtiary ?? '#96f'}
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M1462.944,729,1485,775.412,1408.944,810Z"
        transform="translate(-144.888)"
        fill={colorThirtiary ?? '#96f'}
      />
    </g>
  </svg>
);

export default CrownIcon;
