import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import {
  SiAdobexd,
  SiSass,
  SiSketch,
  SiFirebase,
  SiGithub,
  SiReact
} from 'react-icons/si';
import {
  AiOutlineMobile,
  AiOutlineTablet,
  AiOutlineDesktop
} from 'react-icons/ai';
import { GiOctopus } from 'react-icons/gi';

import design from '../../../assets/img/design.webp';
import light from '../../../assets/img/light.webp';
import mobile from '../../../assets/img/mobile.webp';

import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import ToolsCard from '../../molecules/tools-card/tools-card.component';

import colors from '../../../sass/_variables.module.scss';
import './onze-tools.styles.scss';

const parentVariant = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const OnzeTools = () => (
  <div className="onze-tools-container">
    <div className="onze-tools-content">
      d
      <div className="onze-tools-heading-container">
        <div>
          <HeadingSecondary
            text={'Wij gebruiken up-to-date tools'}
            color={'colorWhite'}
            highlighted={['up-to-date']}
            bold
          />
          <Paragraph
            text={
              'Wij zijn geheel geïnformeerd over de nieuwste development technieken, specifiek front-end gerelateerd. Het leerproces stopt bij ons nooit!'
            }
            color={'colorWhite'}
            opacity={0.9}
          />
        </div>
      </div>
      <motion.div
        variants={parentVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-60px' }}
        className="cards-container"
      >
        <ToolsCard
          heading={'Nieuwste technologieën '}
          text={
            'We maken gebruik van de nieuwste technologieën waardoor onze oplossingen duurzaam zijn in gebruik.'
          }
          iconList={[
            <SiFirebase
              key={'firebase'}
              size={30}
              color={colors.colorSecondary}
            />,
            <SiGithub key={'github'} size={30} color={colors.colorSecondary} />,
            <SiReact key={'react'} size={30} color={colors.colorSecondary} />
          ]}
          img={light}
        />
        <ToolsCard
          heading={'Designed tot perfectie'}
          text={
            'Een exceptioneel design is het begin van elke succesvolle oplossing. You get the look, you get the feel.'
          }
          iconList={[
            <SiAdobexd
              key={'adobexd'}
              size={30}
              color={colors.colorSecondary}
            />,
            <SiSass key={'sass'} size={30} color={colors.colorSecondary} />,
            <SiSketch key={'sketch'} size={30} color={colors.colorSecondary} />
          ]}
          img={design}
        />
        <ToolsCard
          heading={'Volledig responsive'}
          text={
            'Onze oplossingen zijn volledig schaalbaar voor elk type apparaat dat jij mogelijk bezit. '
          }
          iconList={[
            <AiOutlineMobile
              key={'mobile'}
              size={30}
              color={colors.colorSecondary}
            />,
            <AiOutlineTablet
              key={'tablet'}
              size={30}
              color={colors.colorSecondary}
            />,
            <AiOutlineDesktop
              key={'desktop'}
              size={30}
              color={colors.colorSecondary}
            />
          ]}
          img={mobile}
        />
      </motion.div>
    </div>
  </div>
);

export default OnzeTools;
