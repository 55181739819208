import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import './homepage-landing.styles.scss';

import { DiReact, DiSass, DiJavascript1 } from 'react-icons/di';
import { SiAdobexd } from 'react-icons/si';
import { IoIosFlash } from 'react-icons/io';
import { BiGitBranch } from 'react-icons/bi';
import { FiPackage } from 'react-icons/fi';

import HeadingPrimary from '../../atoms/headings/heading-primary/heading-primary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import { scrollTo } from '../../../helpers/scrollTo.js';

const homepageVariantLeft = {
  hidden: {
    opacity: 0,
    y: -10
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

const homepageVariantMiddle = {
  hidden: {
    opacity: 0,
    y: -10
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.5
    }
  }
};
const homepageVariantRight = {
  hidden: {
    opacity: 0,
    x: 10
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.6,
      duration: 0.3,
      when: 'beforeChildren',
      staggerChildren: 0.2
    }
  }
};

const boxText = {
  hidden: {
    opacity: 0,
    x: 20
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5
    }
  }
};

const HomepageLanding = () => {
  const iconSize = '3.5rem';
  const iconOpacity = 0.2;

  return (
    <div className="homepage-landing-container">
      <motion.div
        variants={homepageVariantLeft}
        initial="hidden"
        animate="visible"
        className="homepage-left"
      >
        <HeadingPrimary
          textBold="FAST & EFFECTIVE"
          textThin="SOLUTIONS WITH CSTM"
        />
        <Paragraph
          text={
            'Wij bouwen jouw IT oplossingen in een “CSTM way”: geen gedoe, maar een eerlijk en netjes product. Vraag nu een vrijblijvend kennismakingsgesprek aan!'
          }
          color={'colorPrimary'}
        />

        <div className="get-started-container">
          <div className="get-started-circle"></div>
          <span
            onClick={() => scrollTo('watIsCSTM')}
            className="get-started-text"
          >
            Get started
          </span>
        </div>

        <div className="skill-icons-container">
          <DiReact size={iconSize} opacity={iconOpacity} />
          <DiSass size={iconSize} opacity={iconOpacity} />
          <DiJavascript1 size={iconSize} opacity={iconOpacity} />
          <SiAdobexd size={iconSize} opacity={iconOpacity} />
        </div>
      </motion.div>
      <motion.div
        variants={homepageVariantMiddle}
        initial="hidden"
        animate="visible"
        className="homepage-right"
      >
        <div className="circle-card-container">
          <div className="circle-card circle-card--1">
            <div className="circle circle--1"></div>
          </div>
          <div className="circle-card circle-card--2">
            <div className="circle circle--2"></div>
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={homepageVariantRight}
        initial="hidden"
        animate="visible"
        className="time-line-container"
      >
        <div className="time-line-box-container">
          <div className="time-line-box time-line-box--1">
            <IoIosFlash size={iconSize} className="timeline-icon" />
          </div>
          <motion.span variants={boxText} className="time-line-text">
            Initiate
          </motion.span>
        </div>
        <div className="time-line-box-container">
          <div className="time-line-box time-line-box--2">
            <BiGitBranch size={iconSize} className="timeline-icon" />
          </div>
          <motion.span variants={boxText} className="time-line-text">
            Create
          </motion.span>
        </div>
        <div className="time-line-box-container">
          <div className="time-line-box time-line-box--3">
            <FiPackage size={iconSize} className="timeline-icon" />
          </div>
          <motion.span variants={boxText} className="time-line-text">
            Deliver
          </motion.span>
        </div>
      </motion.div>
    </div>
  );
};
export default HomepageLanding;
