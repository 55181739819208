const CSTMLogo = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={52.1 * size}
      height={47.5 * size}
      viewBox="0 0 521 475"
    >
      <text
        id="C"
        transform="translate(0 380)"
        fill={color ?? '#171a64'}
        font-size="378"
        font-family="Mulish-Black, Mulish"
        font-weight="800"
        letter-spacing="0.02em"
      >
        <tspan x="0" y="0">
          C
        </tspan>
      </text>
      <text
        id="S"
        transform="translate(259 363.001)"
        fill={color ?? '#dec0f1'}
        font-size="124"
        font-family="Mulish-Medium, Mulish"
        font-weight="500"
        letter-spacing="0.02em"
      >
        <tspan x="0" y="0">
          S
        </tspan>
      </text>
      <text
        id="T"
        transform="translate(338 363.001)"
        fill={color ?? '#dec0f1'}
        font-size="124"
        font-family="Mulish-Medium, Mulish"
        font-weight="500"
        letter-spacing="0.02em"
      >
        <tspan x="0" y="0">
          T
        </tspan>
      </text>
      <text
        id="M"
        transform="translate(414 363.001)"
        fill={color ?? '#dec0f1'}
        font-size="124"
        font-family="Mulish-Medium, Mulish"
        font-weight="500"
        letter-spacing="0.02em"
      >
        <tspan x="0" y="0">
          M
        </tspan>
      </text>
      <rect
        id="Rectangle_137"
        data-name="Rectangle 137"
        width="11.963"
        height="153.232"
        rx="5.981"
        transform="translate(266.34 200.183) rotate(-90)"
        fill={color ?? '#dec0f1'}
      />
      <path
        id="Path_676"
        data-name="Path 676"
        d="M5.981,0a5.981,5.981,0,0,1,5.981,5.981v221.9a5.981,5.981,0,0,1-11.963,0V5.981A5.981,5.981,0,0,1,5.981,0Z"
        transform="translate(266.34 233.936) rotate(-90)"
        fill={color ?? '#171a64'}
      />
    </svg>
  );
};

export default CSTMLogo;
