import React from 'react';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';

import './added-value.styles.scss';

const AddedValue = () => {
  return (
    <div className="addedValue-container">
      <div className="heading">
        <HeadingTertiatry text={'activiteiten'} />
        <HeadingSecondary
          text={'Waarom wij waarde toevoegen aan jouw projecten'}
          underlined={['waarde']}
          bold
        />
      </div>
      <div className="addedValue-text">
        <Paragraph
          color={'colorPrimary'}
          text={`CSTM bestaat uit een jong en leergierig team dat kennis bezit over de nieuwste technieken. 
          Gepaard met een relatief laag tarief & maatwerk differentiëren wij ons van onze concurrenten. Wij weten welke technieken het beste bij jouw digitale uitdaging passen. 
          Hiermee maken wij een rendabel en duurzaam product.
          `}
        />

        <Paragraph
          color={'colorPrimary'}
          text={`Gefocust op jouw online success, zijn wij enkel tevreden wanneer jij tevreden bent. 
          Onze stijl als team is het werken tot in de late uurtjes, wat uiteindelijk tot een perfect eindproduct leidt. 
          Denk aan ons als jouw alles-in-één pakket. 
          Eerst samen brainstormen en requirements bepalen waarna we met de juiste zorg jouw product realiseren.`}
        />
      </div>
    </div>
  );
};

export default AddedValue;
