import React from 'react';
import PrimaryBtn from '../../atoms/buttons/primary-btn/primary-btn.component';
import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import './featured-project-card.styles.scss';

function FeaturedProjectCard(date, header) {
  return (
    <div className="project-card-box">
      <div className="project-card-button">
        <PrimaryBtn text="Featured Project" />
      </div>
      <div className="project-card-content">
        <div className="project-card-row">
          <Paragraph text="08/07" />
          <div className="project-card-date-row-img">img</div>
        </div>
        <div className="project-card-row">
          <HeadingSecondary
            bold
            fontsize="1.8rem"
            text="Custom made website "
          />
        </div>
        <div className="project-card-row">
          <div className="project-card-row-client">
            <div className="project-card-client-left">
              <Paragraph text="Client" color="#797979" lessMargin />
            </div>
            <div className="project-card-client-right">
              <Paragraph text="CSTM" color="#797979" />
            </div>
          </div>
        </div>
        <div className="project-card-row-less-margin">
          <div className="project-card-row-client">
            <div className="project-card-client-left">
              <Paragraph text="Client" color="#797979" lessMargin />
            </div>
            <div className="project-card-client-right">
              <Paragraph text="CSTM" color="#797979" />
            </div>
          </div>
        </div>
        <div className="project-card-row">
          <hr className="project-card-line" />
        </div>
        <div className="project-card-row">
          <div className="project-card-row-text">
            <Paragraph text="The creation of a custom made website using Reacct JS framework. Includes complete responsiveness complete design enzo." />
          </div>
        </div>
        <div className="project-card-row">
          <div className="project-card-row-explore-button">
            <PrimaryBtn text="Explore" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProjectCard;
