import React from 'react';

//headings
import HeadingPrimary from '../../atoms/headings/heading-primary/heading-primary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';

//logos and images
import dashboard from '../../../assets/img/dashboard.png';
import Arrow2 from '../../../assets/icons/arrow_2';
import FeaturedProjectCard from '../../molecules/featured-project-card/featured-project-card.component';

import './latest_work.styles.scss';

function LatestWork() {
  return (
    <div className="latest-work-container">
      <div className="latest-work-content">
        <div className="latest-work-content-left">
          <HeadingPrimary textBold="Our latest works" />{' '}
          <div className="latest-work-text-container">
            <div className="latest-work-line"></div>
            <Paragraph text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate hendrerit imperdiet, consectetur adipiscing elit." />
          </div>
          <img
            src={dashboard}
            alt="Dashboard"
            className="latest-work-dashboard-img"
          />
        </div>
        <div className="latest-work-content-middle">
          <Arrow2 size={80} />
        </div>
        <div className="latest-work-content-right">
          <FeaturedProjectCard />
        </div>
      </div>
    </div>
  );
}

export default LatestWork;
