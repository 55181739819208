import React from 'react';

import './ons-team.styles.scss';

import kas from '../../../assets/img/kas.webp';
import thomas from '../../../assets/img/thomas.webp';
import maurice from '../../../assets/img/maurice.webp';
import Man from '../../../assets/businessman.png';
import HeadingPrimary from '../../atoms/headings/heading-primary/heading-primary.component';
import OnsTeamPerson from '../../molecules/ons-team-person/ons-team-person.component';

import Paragraph from '../../atoms/paragraph/paragraph.component';
import PrimaryBtn from '../../atoms/buttons/primary-btn/primary-btn.component';

// Icons
import Arrow2 from '../../../assets/icons/arrow_2';
import Arrow1 from '../../../assets/icons/arrow_1';
import Clover from '../../../assets/icons/clover';

const OnsTeam = () => {
  return (
    <div className="our-team">
      <div className="our-team-content">
        <div className="our-team-top">
          <Clover />
          <HeadingPrimary textBold="Team CSTM" lessMargin />
          <Paragraph text="Ons team blijft hoge kwaliteit software leveren." />
          <PrimaryBtn disabled text="GEEN OPENSTAANDE VACATURES" />
        </div>

        <div className="our-team-bottom">
          <div>
            <OnsTeamPerson
              name="Thomas Nolst Trenité"
              img={thomas}
              jobtitle="Co-Owner, Head Design"
            />
          </div>

          <div>
            {' '}
            <Arrow2 size={70} />{' '}
          </div>

          <div>
            <OnsTeamPerson
              name="Kas Bekker"
              img={kas}
              jobtitle="Co-Owner, Head Analysis"
            />
          </div>

          <div>
            {' '}
            <Arrow1 size={100} />{' '}
          </div>

          <div>
            <OnsTeamPerson
              name="Maurice Koreman"
              img={maurice}
              jobtitle="Co-Owner, Head Development"
            />
          </div>

          <div>
            {' '}
            <Arrow2 size={70} />{' '}
          </div>

          <div>
            <OnsTeamPerson
              name="You?"
              img={Man}
              jobtitle="Neem contact op en wie weet!"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnsTeam;
