const designText = `Oog voor detail betekent oog voor design. In programma's als Adobe XD, Sketch en 
  Figma kunnen wij ontwerpen maken die perfect inzicht geven in hoe het eindproduct eruit komt te zien.
  Zo zijn onze ontwikkelaars in staat om de website in een hoog tempo te ontwikkelen
   zonder kostbare tijd te verliezen.`;

const developmentText = `Vanuit het ontwerp beginnen we met bouwen. 
Hiervoor maken we doorgaans gebruik van het React framework, 
wat het bouwen van jouw oplossing snel, eenvoudig en schaalbaar maakt. 
Naast React gebruiken we technieken zoals Grid en Flexbox om de volledige responsiviteit van elke oplossing te garanderen. 
Technologieën veranderen constant; wij zorgen ervoor dat onze developers up to date blijven.`;

const analyzeText = `Het specialisatie palet van CSTM houdt zich niet alleen bezig met ontwerp en ontwikkeling. 
Wij zijn van alle markten thuis en zetten daarom al onze vaardigheden in om een zo breed mogelijk scala aan activiteiten aan te bieden. 
Enkele voorbeelden van activiteiten die wij aanvullend aanbieden staan hieronder vermeld.`;

export const expertiseObj = {
  one: {
    title: 'Design',
    keywords: [
      'Corporate identity advice',
      'Rebranding',
      'UI Design',
      'UX Design'
    ],
    text: designText
  },
  two: {
    title: 'Development',
    keywords: ['React', 'HTML/CSS', 'React Native', 'Firebase'],
    text: developmentText
  },
  three: {
    title: 'Analyse',
    keywords: [
      'Requirements Engineering',
      'Consultancy',
      'Design Evaluation',
      'Business Analysis'
    ],
    text: analyzeText
  }
};
