import React from 'react';
import { useHistory } from 'react-router-dom';

import HeadingSecondary from '../../atoms/headings/heading-secondary/heading-secondary.component';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';
import Paragraph from '../../atoms/paragraph/paragraph.component';
import PrimaryBtn from '../../atoms/buttons/primary-btn/primary-btn.component';

import './wat-is-cstm.styles.scss';

const WatIsCSTM = () => {
  let history = useHistory();

  function handleClick() {
    history.push('/over-ons');
  }

  return (
    <div id="watIsCSTM" className="watIsCSTM">
      <figure className="watIsCSTM-img"></figure>
      <div>
        <HeadingTertiatry text="Leuk je te ontmoeten!" />
        <HeadingSecondary text="Wat kan CSTM voor je doen?" bold />
        <Paragraph
          cname="watIsCSTM-container-text"
          color={'colorPrimary'}
          text={`Wij bieden een breed scala aan mogelijkheden om jouw uitdagingen aan te gaan. Hetzij het aanleveren van een informatieve website of een geheel bestelsysteem met authenticatie. CSTM heeft juiste kennis in huis om een gepaste oplossing bij jouw digitale uitdaging te vinden.`}
        />
        <PrimaryBtn
          click={handleClick}
          className={'watIsCSTM-btn'}
          outlined
          text="LEES MEER"
        />
      </div>
    </div>
  );
};

export default WatIsCSTM;
