import React from 'react';
import Paragraph from '../../atoms/paragraph/paragraph.component';

import './we_build_block.styles.scss';

const WeBuildBlock = ({ heading, text, icon }) => {
  return (
    <div className='we-build-items'>
      <div className='we-build-items-icon'>
        {icon}
      </div>

      <Paragraph 
        color={'colorPrimary'}
        text={heading}
        cname={'we-build-items-head'}
        bold
        noMargin
      />

      <Paragraph 
        color={'colorGrey'}
        text={text}
        cname={'we-build-items-text'}
        noMargin
      />

    </div>
  );
};

export default WeBuildBlock;
