import { createSlice } from '@reduxjs/toolkit';

export const darkOverlaySlice = createSlice({
  name: 'darkOverlay',
  initialState: {
    hidden: true
  },
  reducers: {
    toggleDarkOverlay: (state) => {
      state.hidden = !state.hidden;
    }
  }
});

export const { toggleDarkOverlay } = darkOverlaySlice.actions;

export default darkOverlaySlice.reducer;
