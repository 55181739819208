import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import './our_story.styles.scss';

import HeadingPrimary from '../../atoms/headings/heading-primary/heading-primary.component';
import HeadingTertiatry from '../../atoms/headings/heading-tertiary/heading-tertiary.component';

import Paragraph from '../../atoms/paragraph/paragraph.component';
import BlocksImage from '../../../assets/blocksImage';
import { IoChevronDownCircle } from 'react-icons/io5';
import { scrollTo } from '../../../helpers/scrollTo';

const MotionParagraph = motion(Paragraph);
const parentVariant = {
  hidden: {
    opacity: 0,
    y: 5
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.3,
      staggerChildren: 0.2,
      when: 'beforeChildren'
    }
  }
};

const childVariant = {
  hidden: {
    opacity: 0,
    y: 5
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4
    }
  }
};

const OurStory = () => {
  return (
    <div className="our-story-wrapper">
      <motion.div variants={parentVariant} initial="hidden" animate="visible">
        <HeadingTertiatry text="ONS VERHAAL" />
        <HeadingPrimary textBold="Oorsprong | CSTM" lessMargin />
        <MotionParagraph
          variants={childVariant}
          text="CSTM Development, opgericht in mei 2021, is ontstaan uit een idee van 3 enthousiaste studenten uit Leiden. Wij geloven dat het combineren van enthousiasme, interesse en skill resulteert in de hoogst mogelijk toegevoegde waarde. Het CSTM skill palet is zo verdeeld dat iedereen één of meer specialismen heeft. Dit varieert van front-end development en design tot analyse om zo jouw uitdaging op elk gebied te laten slagen."
        />
        <MotionParagraph
          variants={childVariant}
          text="De website die je nu bezoekt is één van onze eerste projecten. Het is CSTM-built, en daarmee zonder het gebruik van templates. Wij streven naar hoge kwaliteit en schaalbare oplossingen. Kijk gerust rond!"
        />
        <motion.div variants={childVariant}>
          <IoChevronDownCircle
            onClick={() => scrollTo('whatWeValue')}
            size={45}
            className="our-story-wrapper-arrow"
          />
        </motion.div>
      </motion.div>

      <div>
        <BlocksImage className="our-story-wrapper-image" />
      </div>
    </div>
  );
};

export default OurStory;
