import './terms-and-conditions.styles.scss';

const TermsAndConditions = () => (
  <div className="termsAndConditions">
    <h1>CSTM - Terms and Conditions</h1>
    <article>
      <h2>Artikel 1 - Definities</h2>
      <ol>
        <li>
          Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen,
          werkzaamheden, overeenkomsten en leveringen van diensten of goederen
          door of namens dienstverlener.
        </li>
        <li>
          Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én
          schriftelijk door partijen is overeengekomen.
        </li>
        <li>
          De overeenkomst bevat voor dienstverlener enkel
          inspanningsverplichtingen, geen resultaatverplichtingen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 2 - Toepasselijkheid algemene voorwaarden</h2>
      <ol>
        <li>
          Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen,
          werkzaamheden, overeenkomsten en leveringen van diensten of goederen
          door of namens dienstverlener.
        </li>
        <li>
          Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én
          schriftelijk door partijen is overeengekomen.
        </li>
        <li>
          De overeenkomst bevat voor dienstverlener enkel
          inspanningsverplichtingen, geen resultaatverplichtingen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 3 - Betaling</h2>
      <ol>
        <li>
          Declaraties moeten binnen 14 dagen na factuurdatum betaald zijn,
          tenzij partijen hierover schriftelijk andere afspraken hebben gemaakt
          of op de declaratie een andere betaaltermijn vermeld is.
        </li>
        <li>
          Betalingen geschieden zonder enig beroep op opschorting of verrekening
          door overschrijving van het verschuldigde bedrag op het door
          dienstverlener opgegeven bankrekeningnummer.
        </li>
        <li>
          Betaalt opdrachtgever niet binnen de overeengekomen termijn, dan is
          hij van rechtswege, zonder dat daarvoor enige aanmaning nodig is, in
          verzuim. Vanaf dat moment is dienstverlener gerechtigd de
          verplichtingen op te schorten totdat opdrachtgever aan zijn
          betalingsverplichtingen heeft voldaan.
        </li>
        <li>
          Blijft opdrachtgever in gebreke, dan zal dienstverlener tot
          invordering overgaan. De kosten met betrekking tot die invordering
          komen voor rekening van de opdrachtgever. Wanneer opdrachtgever in
          verzuim is, is hij naast de hoofdsom tevens de wettelijke
          handelsrente, buitengerechtelijke incassokosten en overige schade
          verschuldigd aan dienstverlener. De incassokosten worden berekend aan
          de hand van het Besluit vergoeding voor buitengerechtelijke
          incassokosten (ook wel Besluit BIK genoemd).
        </li>
        <li>
          In geval van liquidatie, faillissement, beslag of surseance van
          betaling van de opdrachtgever zijnde vorderingen van dienstverlener op
          de opdrachtgever onmiddellijk opeisbaar.
        </li>
        <li>
          Weigert opdrachtgever zijn medewerking aan de uitvoering van de
          opdracht door dienstverlener, dan is hij nog steeds verplicht om de
          afgesproken prijs aan dienstverlener te betalen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 4 - Aanbiedingen en offertes</h2>
      <ol>
        <li>
          De aanbiedingen van dienstverlener zijn maximaal 30 dagen geldig,
          tenzij in het aanbod een andere termijn van aanvaarding is genoemd.
          Wordt het aanbod niet binnen die gestelde termijn aanvaard, dan
          vervalt het aanbod.
        </li>
        <li>
          Levertijden in offertes zijn indicatief en geven de opdrachtgever bij
          overschrijding daarvan geen recht op ontbinding of schadevergoeding,
          tenzij partijen uitdrukkelijk én schriftelijk anders zijn
          overeengekomen.
        </li>
        <li>
          Aanbiedingen en offertes gelden niet automatisch voor nabestellingen.
          Partijen moeten dit uitdrukkelijk én schriftelijk overeenkomen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 5 - Prijzen</h2>
      <ol>
        <li>
          De op aanbiedingen, offertes en facturen van dienstverlener genoemde
          prijzen zijn exclusief btw en eventuele andere overheidsheffingen,
          tenzij uitdrukkelijk anders vermeld.
        </li>
        <li>
          Ten aanzien van de dienstverlening kunnen partijen bij de
          totstandkoming van de overeenkomst een vaste prijs overeenkomen.
        </li>
        <li>
          Indien er geen vaste prijs is overeengekomen, kan het tarief met
          betrekking tot de dienstverlening worden vastgesteld op grond van de
          werkelijk bestede uren. Het tarief wordt berekend volgens de
          gebruikelijke uurtarieven van dienstverlener, geldend voor de periode
          waarin hij de werkzaamheden verricht, tenzij een daarvan afwijkend
          uurtarief is overeengekomen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 6 - Prijsindexering</h2>
      <ol>
        <li>
          De bij het aangaan van de overeenkomst overeengekomen prijzen en
          uurlonen zijn gebaseerd op het op dat moment gehanteerde prijspeil.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 7 - Informatieverstrekking door opdrachtgever</h2>
      <ol>
        <li>
          Opdrachtgever stelt alle informatie die voor de uitvoering van de
          opdracht relevant is beschikbaar aan dienstverlener.
        </li>
        <li>
          Opdrachtgever is verplicht alle gegevens en bescheiden, die
          dienstverlener meent nodig te hebben voor het correct uitvoeren van de
          opdracht, tijdig en in de gewenste vorm en op de gewenste wijze ter
          beschikking te stellen.
        </li>
        <li>
          Opdrachtgever staat in voor de juistheid, volledigheid en
          betrouwbaarheid van de aan dienstverlener ter beschikking gestelde
          gegevens en bescheiden, ook indien deze van derden afkomstig zijn,
          voor zover uit de aard van de opdracht niet anders voortvloeit.
        </li>
        <li>
          Opdrachtgever vrijwaart dienstverlener voor elke schade in welke vorm
          dan ook die voortvloeit uit het niet voldoen aan het in dit artikel
          gestelde.
        </li>
        <li>
          Indien en voor zover opdrachtgever dit verzoekt, retourneert
          dienstverlener de betreffende bescheiden.
        </li>
        <li>
          Stelt opdrachtgever niet, niet tijdig of niet behoorlijk de door
          dienstverlener verlangde gegevens en bescheiden beschikbaar en loopt
          de uitvoering van de opdracht hierdoor vertraging op, dan komen de
          daaruit voortvloeiende extra kosten voor rekening van opdrachtgever.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 8 - Intrekking opdracht</h2>
      <ol>
        <li>
          Het staat opdrachtgever vrij om de opdracht aan dienstverlener op elk
          gewenst moment te beëindigen.
        </li>
        <li>
          Wanneer opdrachtgever de opdracht intrekt, is opdrachtgever minstens
          verplicht om de overeengekomen prijs (en bij gebreke daarvan een
          redelijk verschuldigd loon) en de gemaakte onkosten van dienstverlener
          te betalen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 9 - Uitvoering van de overeenkomst</h2>
      <ol>
        <li>
          Dienstverlener voert de overeenkomst naar beste inzicht en vermogen en
          overeenkomstig de eisen van goed vakmanschap uit.
        </li>
        <li>
          Dienstverlener heeft het recht om werkzaamheden te laten verrichten
          door derden.
        </li>
        <li>
          De uitvoering geschiedt in onderling overleg en na schriftelijk
          akkoord en betaling van het eventueel afgesproken voorschot.
        </li>
        <li>
          Het is de verantwoordelijkheid van opdrachtgever dat dienstverlener
          tijdig kan beginnen aan de opdracht.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 10 - Contractduur opdracht</h2>
      <ol>
        <li>
          De overeenkomst tussen opdrachtgever en dienstverlener wordt aangegaan
          voor de duur van de opdracht en – bij gebreke daarvan – voor
          onbepaalde tijd, tenzij uit de aard van de overeenkomst iets anders
          voortvloeit of partijen uitdrukkelijk én schriftelijk anders zijn
          overeengekomen.
        </li>
        <li>
          Zijn partijen binnen de looptijd van de overeenkomst voor de
          voltooiing van bepaalde werkzaamheden een termijn overeengekomen, dan
          is dit nooit een fatale termijn. Bij overschrijding van deze termijn
          moet opdrachtgever dienstverlener schriftelijk in gebreke stellen
          alvorens dienstverlener in verzuim komt.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 11 - Wijziging van de overeenkomst</h2>
      <ol>
        <li>
          Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor
          een behoorlijke uitvoering van de opdracht noodzakelijk is om de te
          verrichten werkzaamheden te wijzigen of aan te vullen, passen partijen
          tijdig en in onderling overleg de overeenkomst dienovereenkomstig aan.
          Als het tijdstip van voltooiing van de uitvoering of de financiële of
          kwalitatieve afspraken daardoor wordt beïnvloed, stelt dienstverlener
          opdrachtgever hiervan zo spoedig mogelijk op de hoogte.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 12 - Overmacht</h2>
      <ol>
        <li>
          In aanvulling op het bepaalde in artikel 6:75 Burgerlijk Wetboek geldt
          dat een tekortkoming van dienstverlener niet aan dienstverlener kan
          worden toegerekend in geval van een buiten de invloedsfeer van
          dienstverlener gelegen omstandigheid, zoals wanprestatie van een
          toeleverancier of andere derden, stroomstoringen, computer virussen,
          stakingen, slechte weersomstandigheden en werkonderbrekingen.
        </li>
        <li>
          Indien zich een situatie als hiervoor bedoeld voordoet als gevolg
          waarvan dienstverlener niet aan zijn verplichtingen jegens
          opdrachtgever kan voldoen, dan worden die verplichtingen opgeschort
          zolang dienstverlener niet aan zijn verplichtingen kan voldoen. Indien
          de in de vorige zin bedoelde situatie 30 kalenderdagen heeft geduurd,
          hebben partijen het recht de overeenkomst schriftelijk geheel of
          gedeeltelijk te ontbinden.
        </li>
        <li>
          Dienstverlener is in het geval als bedoeld in het tweede lid van dit
          artikel niet gehouden tot vergoeding van enige schade, ook niet als
          dienstverlener als gevolg van de overmachttoestand enig voordeel
          geniet.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 13 - Verrekening</h2>
      <ol>
        <li>
          Opdrachtgever heeft geen recht om een vordering van hem op
          dienstverlener te verrekenen met een vordering van dienstverlener op
          hem.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 14 - Aansprakelijkheid schade</h2>
      <ol>
        <li>
          Dienstverlener is niet aansprakelijk voor schade die voortvloeit uit
          deze overeenkomst, tenzij dienstverlener de schade opzettelijk of met
          grove schuld heeft veroorzaakt.
        </li>
        <li>
          In het geval dat dienstverlener een schadevergoeding verschuldigd is
          aan opdrachtgever bedraagt de schade niet meer dan de overeengekomen
          prijs, met in ieder geval een maximum van € 2.500,-.
        </li>
        <li>
          Iedere aansprakelijkheid voor schade, voortvloeiende uit of verband
          houdende met de uitvoering van een overeenkomst, is voorts in ieder
          geval ook beperkt tot het bedrag dat in het desbetreffende geval door
          de gesloten (beroeps)aansprakelijkheidsverzekering(en) wordt
          uitbetaald, minus het bedrag van het eigen risico volgens de
          desbetreffende polis.
        </li>
        <li>
          De aansprakelijkheidsbeperking geldt ook als dienstverlener
          aansprakelijk wordt gesteld voor schade die direct of indirect
          voortvloeit uit het niet deugdelijk functioneren van de door
          dienstverlener bij de uitvoering van de opdracht gebruikte apparatuur,
          software,gegevensbestand, registers of andere zaken.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 15 - Aansprakelijkheid opdrachtgever</h2>
      <ol>
        <li>
          Ingeval een opdracht wordt verstrekt door meer dan één persoon, is
          ieder van hen hoofdelijk aansprakelijk voor de bedragen die uit hoofde
          van die opdracht aan dienstverlener verschuldigd zijn.
        </li>
        <li>
          Wordt een opdracht middellijk of onmiddellijk verstrekt door een
          natuurlijke persoon namens een rechtspersoon, dan kan deze natuurlijke
          persoon tevens in privé opdrachtgever zijn. Daarvoor is vereist dat
          deze natuurlijke persoon beschouwd kan worden als de
          (mede)beleidsbepaler van de rechtspersoon. Bij wanbetaling door de
          rechtspersoon is deze natuurlijke persoon dan persoonlijk
          aansprakelijk voor de betaling van de declaratie, onverschillig of
          deze, al dan niet op verzoek van opdrachtgever, ten name van een
          rechtspersoon dan wel ten name van opdrachtgever als natuurlijk
          persoon of hen beiden is gesteld.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 16 - Vrijwaring</h2>
      <ol>
        <li>
          De opdrachtgever vrijwaart dienstverlener tegen alle aanspraken van
          derden, die verband houden met de door dienstverlener geleverde
          goederen en/of diensten.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 17 - Klachtplicht</h2>
      <ol>
        <li>
          Opdrachtgever is verplicht klachten over de door dienstverlener
          verrichte werkzaamheden direct schriftelijk te melden aan
          dienstverlener. De klacht bevat een zo gedetailleerd mogelijke
          omschrijving van de mogelijke tekortkoming, zodat dienstverlener in
          staat is hierop adequaat te reageren.
        </li>
        <li>
          Een klacht kan er in ieder geval niet toe leiden, dat dienstverlener
          gehouden kan worden om andere werkzaamheden te verrichten dan welke
          zijn overeengekomen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 18 - Intellectueel eigendom</h2>
      <ol>
        <li>
          Tenzij partijen schriftelijk anders zijn overeengekomen, behoudt
          dienstverlener alle intellectuele absolute rechten (waaronder
          auteursrecht, octrooirecht, merkenrecht, tekeningen- en modellenrecht,
          etc.) op al hetgeen hij in het kader van de uitvoering van deze
          overeenkomst gebruikt en/of produceert.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 19 - Geheimhouding</h2>
      <ol>
        <li>
          Ieder der partijen houdt de informatie die hij (in welke vorm dan ook)
          van de andere partij ontvangt en alle andere informatie betreffende de
          andere partij waarvan hij weet of redelijkerwijs kan vermoeden dat
          deze geheim of vertrouwelijk is, dan wel informatie waarvan hij kan
          verwachten dat de verspreiding daarvan de andere partij schade kan
          berokkenen, geheim en neemt alle nodige maatregelen om te waarborgen
          dat zijn personeel de genoemde informatie ook geheimhoudt.
        </li>
        <li>
          De in het eerste lid van dit artikel genoemde geheimhoudingsplicht
          geldt niet voor informatie:
          <ol>
            <li>
              die op het moment dat de ontvanger deze informatie ontving al
              openbaar was of nadien openbaar is geworden zonder een schending
              door de ontvangende partij van een op hem rustende
              geheimhoudingsplicht;
            </li>
            <li>
              waarvan de ontvangende partij kan bewijzen dat deze informatie al
              in zijn bezit was op het moment van verstrekken door de andere
              partij;
            </li>
            <li>
              die de ontvangende partij van een derde heeft ontvangen waarbij
              deze derde gerechtigd was deze informatie aan de ontvangende
              partij te verstrekken;
            </li>
            <li>
              die door de ontvangende partij openbaar wordt gemaakt op grond van
              een wettelijke plicht;
            </li>
          </ol>
        </li>
        <li>
          Overtreedt opdrachtgever het artikel van deze algemene voorwaarden
          over geheimhouding, dan verbeurt opdrachtgever aan dienstverlener een
          onmiddellijk opeisbare boete van € 5.000 voor elke overtreding en
          daarnaast een bedrag van € 500 voor elke dag dat die overtreding
          voortduurt. Dit is ongeacht of de overtreding aan opdrachtgever kan
          worden toegerekend.Bovendien is er voor het verbeuren van deze boete
          geen voorafgaande ingebrekestelling of gerechtelijke procedure
          benodigd. Ook hoeft er geen sprake te zijn van enige vorm van schade.
        </li>
        <li>
          Het verbeuren van de in het eerste lid van dit artikel bedoelde boete
          doet geen afbreuk aan de overige rechten van dienstverlener waaronder
          zijn recht om in plaats van de boete volledige schadevergoeding te
          vorderen.
        </li>
      </ol>
    </article>
    <article>
      <h2>Artikel 20 - Geschillen</h2>
      <ol>
        <li>
          Op de rechtsverhouding tussen partijen is uitsluitend Nederlands recht
          van toepassing.
        </li>
        <li>
          De Rechtbank Midden-Nederland is bevoegd om kennis te nemen van alle
          geschillen die voortvloeien uit de overeenkomst tussen partijen en
          deze algemene voorwaarden.
        </li>
      </ol>
    </article>
  </div>
);

export default TermsAndConditions;
