import React, { useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';

import './arrow-to-top.styles.scss';

const ArrowToTop = () => {
  const [arrowTop, setArrowTop] = useState(false);

  const visibilityStyles = {
    transform: 'rotate(180deg)'
  };

  window.onscroll = function () {
    offSet();
  };

  const offSet = () => {
    if (window.scrollY > 30) {
      setArrowTop(true);
    } else {
      setArrowTop(false);
    }
  };

  const scrollToTop = (direction) => {
    window.scrollTo({
      top: direction ? 0 : 800,
      behavior: 'smooth'
    });
  };

  return (
    <button
      className="arrow-up-container"
      onClick={() => (arrowTop ? scrollToTop(true) : scrollToTop(false))}
    >
      <div
        className="arrow-up-inner-container"
        style={arrowTop ? {} : visibilityStyles}
      >
        <FiChevronUp size={27} color="#FFF" />
      </div>
    </button>
  );
};

export default ArrowToTop;
