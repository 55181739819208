import React from 'react';

const PieChartIcon = ({
  size,
  colorPrimary,
  colorSecondary,
  colorThirtiary
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '204.041'}
    height={size ? size * 0.914 : '186.418'}
    viewBox="0 0 204.041 186.418"
  >
    <g id="Piechart" transform="translate(-783 -650.582)">
      <path
        id="Subtraction_15"
        data-name="Subtraction 15"
        d="M81.312,162.625A80.932,80.932,0,0,1,35.85,148.738,81.551,81.551,0,0,1,6.39,112.963a81.205,81.205,0,0,1,7.5-77.113A81.55,81.55,0,0,1,49.662,6.39,80.8,80.8,0,0,1,81.312,0h.313V81l50.4,63.88a80.969,80.969,0,0,1-50.71,17.746Z"
        transform="translate(783 674.375)"
        fill={colorPrimary ?? '#96f'}
      />
      <path
        id="Intersection_3"
        data-name="Intersection 3"
        d="M0,63.836H81q0-.156,0-.312A81.158,81.158,0,0,0,50.452,0Z"
        transform="matrix(0.788, -0.616, 0.616, 0.788, 830.935, 700.45)"
        fill={colorSecondary ?? '#f9f'}
      />
      <path
        id="Intersection_4"
        data-name="Intersection 4"
        d="M0,0V81.625A81.312,81.312,0,0,0,81,.312Q81,.156,81,0Z"
        transform="matrix(0.788, -0.616, 0.616, 0.788, 872.959, 754.978)"
        fill={colorThirtiary ?? '#d580ff'}
      />
    </g>
  </svg>
);

export default PieChartIcon;
