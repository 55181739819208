import './hamburger-icon.styles.scss';

const HamburgerIcon = ({ onClick, open }) => (
  <div onClick={onClick} className={`${open ? 'open' : ''} nav-icon`}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default HamburgerIcon;
