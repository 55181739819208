import React from 'react';
import './input-field.styles.scss';

const InputField = ({ type, placeholder, lg, rows, name, ...props }) => {
  return lg ? (
    <textarea
      className={'input lg'}
      placeholder={placeholder}
      rows={rows}
      name={name}
      {...props}
    />
  ) : (
    <input
      className={'input regular'}
      type={type}
      placeholder={placeholder}
      name={name}
      {...props}
    />
  );
};
    
export default InputField;
