import React from 'react';

// Import components
import OurStory from '../../components/organisms/our-story/our_story.component';
import StayUpToDate from '../../components/organisms/stay-up-to-date/stay_up_to_date.component';
import WeBuild from '../../components/organisms/we-build/we_build.compontent';
import WhatWeValue from '../../components/organisms/what-we-value/what_we_value.component';
import Footer from '../../components/organisms/footer-section/footer.component';
import OnsTeam from '../../components/organisms/ons-team/ons-team.component';

import './over_ons.styles.scss';

const OverOns = () => {
  return (
    <main className="overons-container">
      <section className="overons-landing">
        <OurStory />
      </section>
      <section className="what-we-value">
        <WhatWeValue />
      </section>
      <section className="our-team">
        <OnsTeam />
      </section>
      <section className="we-build">
        <WeBuild />
      </section>
      {/* <section className="stay-up-to-date">
        <StayUpToDate />
      </section> */}
      <section className="homepage-footer">
        <Footer />
      </section>
    </main>
  );
};

export default OverOns;
