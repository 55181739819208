import React from 'react';

const CloverIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '61.756'}
    height={size ? size * 0.852 : '52.645'}
    viewBox="0 0 370.537 315.87"
  >
    <path
      id="Clover"
      d="M1009.607,725.649c7.968-28.614,12.661-43.517,27.89-66.283,51.511-67.332,90.611-84.762,97.693-89.482,42.534-28.356,82.029,8.607,64.461,47.81-25.1,56-127.129,63.361-173.65,30.306s-44.255-37.764-88.045-72-104.136-8.881-62.54,56.315c45.343,61.258,104.447,29.618,136.465,0s88.5-90.617,65.561-162.674-84.6-40.9-87.119,15.138,12.859,82.085,31.505,102.231"
      transform="translate(-847.439 -413.535)"
      fill="none"
      stroke={color ?? '#dec0f1'}
      strokeWidth="28"
    />
  </svg>
);

export default CloverIcon;
