import React from 'react';
import Paragraph from '../../atoms/paragraph/paragraph.component';

import './ons-team-person.styles.scss';

const OnsTeamPerson = ({ name, jobtitle, img }) => {
  return (
    <div className="person">
      <figure className="person-fig">
        <img src={img} alt={name} className="person-fig-img" />
      </figure>
      <Paragraph
        color={'colorPrimary'}
        text={name}
        cname={'person-name'}
        lessMargin
      />

      <Paragraph
        color={'colorPrimary'}
        text={jobtitle}
        cname={'person-function'}
        lessMargin
      />
    </div>
  );
};

export default OnsTeamPerson;
