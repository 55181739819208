import React from 'react';

const Arrow3 = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '100'}
    viewBox="0 0 199.22 43.56"
  >
    <title>Asset 4</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M155.62,21.54C147.18,14.42,138.68,7.39,130.2.32l-1.42,1.84c10.7,4.53,22.35,7.41,33.42,10.91l35.13,11.08V21.22a474.23,474.23,0,0,1-61.59,19.21l1.8,2.34c4.63-7.72,10.05-13.94,18-18.39,2-1.14.2-4.28-1.82-3.12a46.58,46.58,0,0,0-18.92,19.9,1.61,1.61,0,0,0,1.8,2.34,614,614,0,0,0,61.55-19.35,1.54,1.54,0,0,0,0-2.93L164,10.47c-11.28-3.55-22.7-8-34.27-10.45-1-.22-2.12,1.17-1.13,2q12.94,10.38,26,20.65c.77.61,1.87-.44,1.1-1.09Z"
          fill={color ?? '#dec0f1'}
        />
        <path
          d="M146.66,20c-11.87-1.13-24.18-.15-36.12-.47Q92,19,73.49,17.42C49.2,15.31,25.26,10.68,1,8.37-.21,8.26-.36,10.1.74,10.44c23.31,7.29,48.48,9.65,72.75,11.46Q92,23.28,110.54,23.53c11.93.17,24.65.83,36.44-1.19,1.42-.25.92-2.23-.32-2.35Z"
          fill={color ?? '#dec0f1'}
        />
      </g>
    </g>
  </svg>
);

export default Arrow3;
